import React from "react";
import Standard from "../assets/images/produk/Standart-Blue.jpg";
import HITemp from "../assets/images/produk/Hi-Temp-White.jpg";
import DChrome from "../assets/images/produk/Chrome-Blue-.jpg";
import Flintkote from "../assets/images/produk/Flintkote-White.jpg";
import Magic from "../assets/images/produk/Magic-Blue.jpg";
import DitonGold from "../assets/images/produk/G24-White.png";
import FreeCrack from "../assets/images/icon/DS-_Icon-Free Crack.png";
import QuickDry from "../assets/images/icon/DS-_Icon-Quick Dry.png";
import SuperGlosy from "../assets/images/icon/DS-_Icon-Super Glossy.png";
import Multipurpose from "../assets/images/icon/DS-_Icon-Multipurpose.png";
import HeatResist from "../assets/images/icon/HI Temp_Icon-Tahan 600º.png";
import NoMelt from "../assets/images/icon/HI Temp_Icon-Tidak Meleleh.png";
import GasolineHold from "../assets/images/icon/HI Temp_Icon-Tahan Bensin.png";
import ShineLikeaGold from "../assets/images/icon/USP GOLD_Shine Like Real Gold.png";
import GoldLuxury from "../assets/images/icon/USP GOLD_Luxury Gold.png";
import HighBriliance from "../assets/images/icon/USP GOLD_High Brilliance.png";
import icon from "../assets/images/logo/iconUSP.png";
import DIY1 from "../assets/images/DIY/DIY1.jpg";
import DIY2 from "../assets/images/DIY/DIY2.jpg";
import DIY3 from "../assets/images/DIY/DIY3.jpg";
import DIY4 from "../assets/images/DIY/DIY4.jpg";
import DIY5 from "../assets/images/DIY/DIY5.jpg";
import DIY6 from "../assets/images/DIY/DIY6.jpg";
import DIY7 from "../assets/images/DIY/DIY7.jpg";
import DIY8 from "../assets/images/DIY/DIY8.jpg";
import DIY9 from "../assets/images/DIY/DIY9.jpg";
import DIY10 from "../assets/images/DIY/DIY10.jpg";
import DIY11 from "../assets/images/DIY/DIY11.jpg";
import DIY12 from "../assets/images/DIY/DIY12.jpg";
import HeroStandard from "../assets/images/produk/Hero Spray Paint.png";
import HeroHITemp from "../assets/images/produk/Hero Hi Temp.png";
import WhiteDoff from "../assets/images/color_chart/SOLID/WHITEDOFF-SOLIDCOLOR.png";
import ClearDoff from "../assets/images/color_chart/SOLID/CLEARDOFF-SOLIDCOLOR.png";
import OceanBlue from "../assets/images/color_chart/SOLID/OCEANBLUE-SOLIDCOLOR.png";
import SignalRed from "../assets/images/color_chart/SOLID/SIGNALRED-SOLIDCOLOR.png";
import MediumGrey from "../assets/images/color_chart/SOLID/MEDIUMGREY-SOLIDCOLOR.png";
import SoftPink from "../assets/images/color_chart/SOLID/SOFTPINK-SOLIDCOLOR.png";
import DarkBlue from "../assets/images/color_chart/SOLID/DARKBLUE-SOLIDCOLOR.png";
import RosePink from "../assets/images/color_chart/SOLID/ROSEPINK-SOLIDCOLOR.png";
import NavyBlue from "../assets/images/color_chart/SOLID/NAVYBLUE-SOLIDCOLOR.png";
import LightGrey from "../assets/images/color_chart/SOLID/LIGHTGREY-SOLIDCOLOR.png";
import Maroon from "../assets/images/color_chart/SOLID/MAROON-SOLIDCOLOR.png";
import BlackSemiDoff from "../assets/images/color_chart/SOLID/BLACKSEMIDOFF-SOLIDCOLOR.png";
import SpecialRed from "../assets/images/color_chart/SOLID/SPECIALRED-SOLIDCOLOR.png";
import ExclusiveMaroon from "../assets/images/color_chart/SOLID/EXCLUSIVEMAROON-SOLIDCOLOR.png";
import StrongBlue from "../assets/images/color_chart/SOLID/STRONGBLUE-SOLIDCOLOR.png";
import SapphireBlue from "../assets/images/color_chart/SOLID/SAPPHIREBLUE-SOLIDCOLOR.png";
import RoyalBlue from "../assets/images/color_chart/SOLID/ROYALBLUE-SOLIDCOLOR.png";
import BellFlower from "../assets/images/color_chart/SOLID/BELLFLOWER-SOLIDCOLOR.png";
import UltraWhite from "../assets/images/color_chart/SOLID/ULTRAWHITE-SOLIDCOLOR.png";
import ExecutiveGrey from "../assets/images/color_chart/SOLID/EXECUTIVEGREY-SOLIDCOLOR.png";
import ToscaGreen from "../assets/images/color_chart/SOLID/TOSCAGREEN-SOLIDCOLOR.png";
import TobaccoBrown from "../assets/images/color_chart/SOLID/TOBACCOBROWNL300-SOLIDCOLOR.png";
import IndigoBlue from "../assets/images/color_chart/SOLID/INDIGOBLUE-SOLIDCOLOR.png";
import ArmyGreen from "../assets/images/color_chart/SOLID/ARMYGREEN-SOLIDCOLOR.png";
import PhoenixGreen from "../assets/images/color_chart/SOLID/PHOENIXGREEN-SOLIDCOLOR.png";
import MadorasBrown from "../assets/images/color_chart/SOLID/MADORASBROWN-SOLIDCOLOR.png";
import Titanium from "../assets/images/color_chart/metallic/Titanium-Metal-Color.png";
import Gold from "../assets/images/color_chart/metallic/Gold-MEtal-Color.png";
import Silver from "../assets/images/color_chart/metallic/Silver-Metal-Color.png";
import VarioPink from "../assets/images/color_chart/metallic/Vario-PInk-Automotive-Color.png";
import Bronze from "../assets/images/color_chart/metallic/Bronze-Metal-Color.png";
import MutiaraPutih from "../assets/images/color_chart/Mutiara/Mutiara-Putih-Mutiara-Color.png";
import MutiaraKuning from "../assets/images/color_chart/Mutiara/Mutiara-Kuning-Mutiara-Color.png";
import MutiaraGrey from "../assets/images/color_chart/Mutiara/Mutiara-Grey-Mutiara-Color.png";
import MutiaraUngu from "../assets/images/color_chart/Mutiara/Mutiara-Ungu-Mutiara-Color.png";
import MutiaraHijau from "../assets/images/color_chart/Mutiara/Mutiara-Hijau-Mutiara-Color.png";
import MutiaraPink from "../assets/images/color_chart/Mutiara/Mutiara-Pink-Mutiara-Color.png";
import MutiaraBiru from "../assets/images/color_chart/Mutiara/Mutiara-Blue-Mutiara-Color.png";
import MetallicYellow from "../assets/images/color_chart/metallic/Metallic-Yellow-Metallic-Color.png";
import EccoGreen from "../assets/images/color_chart/metallic/Ecco-Green-Metallic-Color.png";
import MetallicBlack from "../assets/images/color_chart/metallic/Metallic-Black-Metallic-Color.png";
import BluePurple from "../assets/images/color_chart/metallic/Blue-Purple-Metallic-Color.png";
import CarbicBlue from "../assets/images/color_chart/metallic/Carabic-Blue-Metallic-Color.png";
import MilanoBlue from "../assets/images/color_chart/metallic/Minalo-Blue-Metallic-Color.png";
import JupiterBlue from "../assets/images/color_chart/metallic/Jupiter-Blue-Automotive-Color.png";
import NinjaGreen from "../assets/images/color_chart/metallic/Ninja-Green-Automotive-Color.png";
import JupiterOrange from "../assets/images/color_chart/metallic/Jupiter-Orange-Automotive-Color.png";
import ShogunBlue from "../assets/images/color_chart/metallic/Shogun-Blue-Automotive-Color.png";
import ShogunGreen from "../assets/images/color_chart/metallic/Shogun-Green-Automotive-Color.png";
import ShogunYellow from "../assets/images/color_chart/metallic/Shogun-Yellow-Automotive-Color.png";
import Neptune from "../assets/images/color_chart/metallic/Neptune-Metallic-Color.png";
import CoralGreen from "../assets/images/color_chart/metallic/Coral-Color-Metallic-Color.png";
import LagunaGreen from "../assets/images/color_chart/metallic/Laguna-Green-Metallic-Color.png";
import MetallicRed from "../assets/images/color_chart/metallic/Metallic-Red-Metallic-Color.png";
import BluePearl from "../assets/images/color_chart/metallic/Blue-Pearl-Metallic-Color.png";
import SonicBlue from "../assets/images/color_chart/metallic/Sonic-Blue-Metallic-Color.png";
import NeonGreen from "../assets/images/color_chart/metallic/Neon-Green-Metallic-Color.png";
import CelestialBlue from "../assets/images/color_chart/metallic/Celestial-Blue-Metallic-Color.png";
import EmeraldGreen from "../assets/images/color_chart/metallic/Emerald-Green-Metallic-Color.png";
import PineGreen from "../assets/images/color_chart/metallic/Pine-Green-Metallic-Color.png";
import MetallicGrey from "../assets/images/color_chart/metallic/Mettalic-Grey-Metallic-Color.png";
import MetallicBrown from "../assets/images/color_chart/metallic/Metallic-Brown-Metallic-Color.png";
import EmperorRed from "../assets/images/color_chart/metallic/Emperor-Red-Metallic-Color.png";
import CuracaoBlue from "../assets/images/color_chart/metallic/Curacao-Blue-Metallic-Color.png";
import BluishGrey from "../assets/images/color_chart/metallic/Bluish-Grey-Metallic-Color.png";
import NatierBlue from "../assets/images/color_chart/metallic/Natier-Blue-Metallic-Color.png";
import FormicaBeige from "../assets/images/color_chart/metallic/Formica-Beige-Metallic-Color.png";
import AnthraciteGrey from "../assets/images/color_chart/metallic/Anthracite-Grey-Metallic-Color.png";
import DaytonaViolet from "../assets/images/color_chart/metallic/Daytona-Violet-Metallic Color.png";
import SapphireBlack from "../assets/images/color_chart/metallic/Sapphire-Black-Metallic-Color.png";
import Black from "../assets/images/color_chart/Hi Temp/Black-Hi-Temp.png";
import HI_Bronze from "../assets/images/color_chart/Hi Temp/Bronze-Hi-Temp.png";
import HI_Silver from "../assets/images/color_chart/Hi Temp/Silver-Hi-Temp.png";
import HondaRed from "../assets/images/color_chart/Candytone/Honda-Red-Candytone-Color.png";
import HondaYellow from "../assets/images/color_chart/Candytone/Honda-Yellow-Candytone-Color.png";
import HondaBlue from "../assets/images/color_chart/Candytone/Honda-Blue-Candytone-Blue.png";
import YamahaGreen from "../assets/images/color_chart/Candytone/Yamaha-Green-Candytono-Color.png";
import SuzukiBlue from "../assets/images/color_chart/Candytone/Suzuki-Blue-Candytone-Color.png";
import YamahaRed from "../assets/images/color_chart/Candytone/Yamaha-Red-Candytone-Color.png";
import Tembaga from "../assets/images/color_chart/Special Color/Tembaga-Special-Color.png";
import SpecialGold from "../assets/images/color_chart/Special Color/Special-Gold-Sppecial-Color.png";
import Chrome from "../assets/images/color_chart/Special Color/Chrome-Special-Color.png";
import PremierGrey from "../assets/images/color_chart/Undercoat/PP-Primer-Grey-Undercoat.png";
import FLINTKOTE from "../assets/images/color_chart/Undercoat/Flintkote-Under-Coat.png";
import Red from "../assets/images/color_chart/Fluorescent/Red-Fluorescent.png";
import Pink from "../assets/images/color_chart/Fluorescent/Pink-Fluorescent.png";
import Green from "../assets/images/color_chart/Fluorescent/Green-Fluorescent.png";
import Blue from "../assets/images/color_chart/Fluorescent/Blue-Fluorenscent.png";
import Yellow from "../assets/images/color_chart/Fluorescent/Yellow-Fluorescent.png";
import Orange from "../assets/images/color_chart/Fluorescent/Orange-Fluorescent.png";
import GrassGreen from "../assets/images/color_chart/SOLID/GRASSGREEN-SOLIDCOLOR.png";
import BalticWhite from "../assets/images/color_chart/SOLID/BALTICWHITE-SOLIDCOLOR.png";
import BlackDoff from "../assets/images/color_chart/SOLID/BLACKDOFF-SOLIDCOLOR.png";
import Ivory from "../assets/images/color_chart/SOLID/IVORY-SOLIDCOLOR.png";
import FreshGreen from "../assets/images/color_chart/SOLID/FRESHGREEN-SOLIDCOLOR.png";
import ColtYellow from "../assets/images/color_chart/SOLID/COLTYELLOW-SOLIDCOLOR.png";
import MikroletBlue from "../assets/images/color_chart/SOLID/MIKROLETBLUE-SOLIDCOLOR.png";
import White from "../assets/images/color_chart/SOLID/WHITE-SOLIDCOLOR.png";
import CoklatMuda from "../assets/images/color_chart/SOLID/COKLATMUDA-SOLIDCOLOR.png";
import Clear from "../assets/images/color_chart/SOLID/CLEAR-SOLIDCOLOR.png";
import SatinGreen from "../assets/images/color_chart/SOLID/SATINGREEN-SOLIDCOLOR.png";
import DakarYellow from "../assets/images/color_chart/SOLID/PS.DAKARYELLOW-SOLIDCOLOR.png";
import DarkYellow from "../assets/images/color_chart/SOLID/DARKYELLOW-SOLIDCOLOR.png";
import HinoGreen from "../assets/images/color_chart/SOLID/HINOGREEN-SOLIDCOLOR.png";
import ChromeYellow from "../assets/images/color_chart/SOLID/CHROMEYELLOW-SOLIDCOLOR.png";
import CeruleanBlue from "../assets/images/color_chart/SOLID/CERULEANBLUE-SOLIDCOLOR.png";
import SunkistOrange from "../assets/images/color_chart/SOLID/SUNKISTORANGE-SOLIDCOLOR.png";
import DeepGreen from "../assets/images/color_chart/SOLID/DEEPGREEN-SOLIDCOLOR.png";
import OlimpiaYellow from "../assets/images/color_chart/SOLID/OLIMPIAYELLOW-SOLIDCOLOR.png";
import ExecutiveBlue from "../assets/images/color_chart/SOLID/EXCLUSIVEBLUE-SOLIDCOLOR.png";
import VictoriaBlue from "../assets/images/color_chart/SOLID/VICTORIABLUE-SOLIDCOLOR.png";
import PurpleAngel from "../assets/images/color_chart/SOLID/PURPLEANGEL-SOLIDCOLOR.png";
import DarkGrey from "../assets/images/color_chart/SOLID/DARKGREY-SOLIDCOLOR.png";
import SpecialPurple from "../assets/images/color_chart/metallic/Special-Purple-Metallic Color.png";
import ClearMetallic from "../assets/images/color_chart/metallic/Clear-Metalic-Metallic-Color.png";
import SilverMetallic from "../assets/images/color_chart/metallic/Silver-Metallic-Metallic-Color.png" /*"../assets/images/color_chart/metallic/8580_Silver_Metallic"*/;
import RedPurple from "../assets/images/color_chart/metallic/Red-Purple-Metallic-Color.png";
import LightGreen from "../assets/images/color_chart/metallic/Light-Green-Metallic-Color.png";
import CypressGreen from "../assets/images/color_chart/metallic/Cypress-Green-Metaliic-Color.png";
import NeonBlue from "../assets/images/color_chart/metallic/Neon-Blue-Metallic-Color.png";
import CenturyBlue from "../assets/images/color_chart/metallic/Century-Blue-Metallic-Color.png";
import BrungRed from "../assets/images/color_chart/metallic/Burgund-Red-Metallic-Color.png";
//belum ada buletan waran
import Magenta from "../assets/images/color_chart/SOLID/Magenta.png";
import GojekGreen from "../assets/images/color_chart/SOLID/Gojek-Green.png";
import IronMetallicDoff from "../assets/images/color_chart/metallic/Iron-Metallic-Doff.png";
import MagentaMetallic from "../assets/images/color_chart/metallic/Magenta-Metallic.png";
import CandyPink from "../assets/images/color_chart/Candytone/Candy-Pink.png";
import CandyViolet from "../assets/images/color_chart/Candytone/CandyViolet.png";
export const MenuData = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "Product",
    url: "/Products",
    cName: "nav-links",
    childerLink: [
      {
        childUrl: "Standart",
      },
      {
        childUrl: "HITemp",
      },
      {
        childUrl: "Chrome",
      },
      {
        childUrl: "Flintkote",
      },
      // {
      //   childUrl: "Magic",
      // },
      {
        childUrl: "diton-gold-series",
      },
    ],
  },
  {
    title: "DIY",
    url: "/DIY",
    cName: "nav-links",
  },
  {
    title: "Digital Catalog",
    url: "/ColorChart",
    cName: "nav-links",
  },
];

export const USPDataSprayPaint = [
  {
    img: FreeCrack,
    title: "Anti Retak",
    dec: "Diton Spray Paint menghasilkan hasil yang rapi dan tidak mudah retak",
  },
  {
    img: QuickDry,
    title: "Cepat Kering",
    dec: "Pegecatan menggunakan Diton Spray Paint tidak memerlukan waktu lama karena cepat kering.",
  },
  {
    img: SuperGlosy,
    title: "Kilap Maksimal",
    dec: "Diton Spray Paint terbuat dari pernis akrilik, membuat hasil pengecatan lebih glossy.",
  },
  {
    img: Multipurpose,
    title: "Multipurpose",
    dec: "Diton Spray Paint dapat digunakan berbagai media , seperti pelastik, kayu, besi, akrilik, dan lain-lain.",
  },
];

export const USPDataHITemp = [
  {
    img: FreeCrack,
    title: "Anti Retak",
    dec: "Diton Hi-Temp menghasilkan hasil yang rapi dan tidak mudah retak.",
  },
  {
    img: GasolineHold,
    title: "Tahan Bensin",
    dec: "Diton Hi-Temp tebukti sebagai salah satu cat semprot yang tidak luntur terkena bensin.",
  },
  {
    img: HeatResist,
    title: "Tahan Panas Hingga 600℃",
    dec: "Meiliki ketahanan panas hingga 600℃.",
  },
  {
    img: NoMelt,
    title: "Tidak Meleleh",
    dec: "Dijamin tidak mudah leleh dan terkelupas pada suhu tinggi.",
  },
];
export const USPDataChrome = [
  {
    img: FreeCrack,
    title: "Anti Retak",
    dec: "Diton Chrome menghasilkan hasil yang rapi dan tidak mudah retak",
  },
  {
    img: QuickDry,
    title: "Cepat Kering",
    dec: "Pegecatan menggunakan Diton Chrome tidak memerlukan waktu lama karena cepat kering.",
  },
  {
    img: icon,
    title: "Daya tutup maksimal",
    dec: "Memberikan daya tutup cat yang maksimal",
  },
  {
    img: icon,
    title: "Mirror Effek",
    dec: "Memberikan efek warna seperti chrome sungguhan",
  },
];
export const USPDataFlintkote = [
  {
    img: FreeCrack,
    title: "Anti Retak",
    dec: "Diton Flintkote menghasilkan hasil yang rapi dan tidak mudah retak.",
  },
  {
    img: QuickDry,
    title: "Cepat Kering",
    dec: "Pegecatan menggunakan Diton Flintkote tidak memerlukan waktu lama karena cepat kering.",
  },
  {
    img: icon,
    title: "Daya tutup maksimal",
    dec: "Memberikan daya tutup cat yang maksimal",
  },
  {
    img: icon,
    title: "Anti Karat",
    dec: "Daya tahan yang sangat baik terhadap karat",
  },
];
// usp magic belum ada
export const USPDataMagic = [
  {
    img: FreeCrack,
    title: "Anti Retak",
    dec: "Diton Flintkote menghasilkan hasil yang rapi dan tidak mudah retak.",
  },
  {
    img: QuickDry,
    title: "Cepat Kering",
    dec: "Pegecatan menggunakan Diton Flintkote tidak memerlukan waktu lama karena cepat kering.",
  },
  {
    img: icon,
    title: "Daya tutup maksimal",
    dec: "Memberikan daya tutup cat yang maksimal",
  },
  {
    img: icon,
    title: "Anti Karat",
    dec: "Daya tahan yang sangat baik terhadap karat",
  },
];
export const USPDataGold = [
  {
    img: QuickDry,
    title: "Cepat Kering",
    dec: "Pegecatan menggunakan Diton Gold 18 Karat dan 24 Karat tidak memerlukan waktu lama karena cepat kering.",
  },
  {
    img: GoldLuxury,
    title: "Hasil Efek Mewah",
    dec: "Diton Gold Series memiliki warna emas yang memberikan kesan mewah pada berbagai media",
  },
  {
    img: HighBriliance,
    title: "Kecerahan warna tinggi",
    dec: "Diton Gold Series menghasilkan warna yang sangat berkilau.",
  },
  {
    img: ShineLikeaGold,
    title: "Berkilau Seperti Emas Asli",
    dec: "Warna Diton Gold sangat menyerupai emas asli 18 Karat dan 24 Karat.",
  },
];
export const ProductList = [
  {
    href: "/Products/Standart",
    alt: "Diton HeroStandard",
    url: Standard,
    name: "SPRAY PAINT",
  },
  {
    href: "/Products/HITemp",
    alt: "Diton HI-Temp",
    url: HITemp,
    name: "HI-TEMP",
  },
  {
    href: "/Products/Chrome",
    alt: "Diton Chrome",
    url: DChrome,
    name: "CHROME",
  },
  {
    href: "/Products/Flintkote",
    alt: "Diton Flintkote",
    url: Flintkote,
    name: "FLINTKOTE",
  },
  {
    href: "/Products/Magic",
    alt: "Diton Magic",
    url: Magic,
    name: "MAGIC",
  },
  {
    href: "/Products/diton-gold-series",
    alt: "Diton Gold",
    url: DitonGold,
    name: "GOLD SERIES",
  },
];

export const VideoCarousel = [
  {
    id: 1,
    urlVideo: "https://www.youtube.com/embed/kPfawFit5bI",
    thumbnail: "https://img.youtube.com/vi/kPfawFit5bI/maxresdefault.jpg",
    titleVideo: "PERHATIKAN ALAT INI SAAT PROSES REPAINT | DITON SPRAY PAINT",
    caption:
      "Saat proses repaint alangkah baiknya kalian menggunakan alat pelindung seperti sarung tangan, masker, dll. Bertujuan agar kalian tidak tepapar partikel-partikel dari cat semprot maupun iritasi. Untuk itu kalian harus simak penjalasan divideo ini!",
  },
  {
    id: 2,
    urlVideo: "https://www.youtube.com/embed/FlnXByyrOmM",
    thumbnail: "https://img.youtube.com/vi/FlnXByyrOmM/maxresdefault.jpg",
    titleVideo: "RECAP VIDEO DITON SPRAY PAINT DI COMIC FRONTIER 16",
    caption:
      "Ini dia keseruan di acara COMIC FRONTIER 16 2023 (COMIFURO)Ada banyak keseruan di Booth salah satunya penampilan dari kak Refeen, Ari Santosa, Geonomu dan beberapa cosplayer lainnya.Selain itu terdapat diskon 10% untuk pembelian semua produk Diton dan hadiah menarik lainnya selama event berlangsung.Terima kasih sudah memeriahkan Booth DITON SPRAY PAINT di acara COMIC FRONTIER 16 di ICE BSD 6 - 7 MEI 2023",
  },
  {
    id: 3,
    urlVideo: "https://www.youtube.com/embed/xUDvw___ZLU",
    thumbnail: "https://img.youtube.com/vi/xUDvw___ZLU/maxresdefault.jpg",
    titleVideo: "REPAINT DIBAHAN KAIN EMANG BISA? | DITON SPRAY PAINT",
    caption:
      "Kali ini mimin mau repaint media yang beda dari biasanya nih, kalau biasanya mimin repaint barang-barang material kerasDikonten kali ini mimin mau mencoba repaint jaket denim dengan Diton Spray Paint, jadi bisa dikerasikan sesua keinginan kaliankira-kira hasilnya bagaimana, Yuk simak video berikut ini!!!",
  },
  {
    id: 4,
    urlVideo: "https://www.youtube.com/embed/zPDCwlBeyX8",
    thumbnail: "https://img.youtube.com/vi/zPDCwlBeyX8/maxresdefault.jpg",
    titleVideo: "TIPS REPAINT CASING HP | DITON SPRAY PAINT",
    caption:
      "Lakukan hal ini, ketika kalian mau merepaint casing HP kesayangan agar tahan lama😍",
  },
  {
    id: 5,
    urlVideo: "https://www.youtube.com/embed/Y_jMX3GL0GY",
    thumbnail: "https://img.youtube.com/vi/Y_jMX3GL0GY/maxresdefault.jpg",
    titleVideo: "TIPS REPAINT CASING HP | DITON SPRAY PAINT",
    caption:
      "Lakukan hal ini, ketika kalian mau merepaint casing HP kesayangan agar tahan lama😍",
  },
  {
    id: 6,
    urlVideo: "https://www.youtube.com/embed/lEe_uhhJqF0",
    thumbnail: "https://img.youtube.com/vi/lEe_uhhJqF0/maxresdefault.jpg",
    titleVideo: "TIPS REPAINT CASING HP | DITON SPRAY PAINT",
    caption:
      "Lakukan hal ini, ketika kalian mau merepaint casing HP kesayangan agar tahan lama😍",
  },
];

export const Products = [
  {
    id: 1,
    productName: "Diton Spray Paint",
    productVideo: 'https://www.youtube.com/embed/Pr6P-fhNVF4"',
    HowtoUse: [
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
    ],
  },
  {
    id: 2,
    productName: "HI-Temp",
    productVideo: 'https://www.youtube.com/embed/Pr6P-fhNVF4"',
    HowtoUse: [
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
    ],
  },
  {
    id: 3,
    productName: "Chrome",
    productVideo: 'https://www.youtube.com/embed/Pr6P-fhNVF4"',
    HowtoUse: [
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
    ],
  },
  {
    id: 4,
    productName: "Flintkote",
    productVideo: 'https://www.youtube.com/embed/Pr6P-fhNVF4"',
    HowtoUse: [
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
      "https://dummyimage.com/340x380/cfcfcf/000.png",
    ],
  },
];

export const Colours = [
  {
    id: 1,
    ColorsName: "SOLID COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: GrassGreen,
        ColorCode: "837",
        ColorName: "GRASS GREEN",
        UrlVideo: "https://www.youtube.com/embed/ZYdbaWbiWmY",
      },
      {
        idColor: 2,
        ColorImg: BalticWhite,
        ColorCode: "847",
        ColorName: "BALTIC WHITE",
        UrlVideo: "https://www.youtube.com/embed/4kOm_rmKrVE",
      },
      {
        idColor: 2,
        ColorImg: UltraWhite,
        ColorCode: "8458",
        ColorName: "ULTRA WHITE",
        UrlVideo: "https://www.youtube.com/embed/1hXoCdK2IMw",
      },
      {
        idColor: 2,
        ColorImg: BlackDoff,
        ColorCode: "884",
        ColorName: "BLACK DOFF",
        UrlVideo: "https://www.youtube.com/embed/fqor5B2_fA0",
      },
      {
        idColor: 2,
        ColorImg: WhiteDoff,
        ColorCode: "820",
        ColorName: "WHITE DOFF",
        UrlVideo: "https://www.youtube.com/embed/2EmZQSBBrrc",
      },
      {
        idColor: 2,
        ColorImg: Ivory,
        ColorCode: "815",
        ColorName: "IVORY",
        UrlVideo: "https://www.youtube.com/embed/h3656nPSkjs",
      },
      {
        idColor: 2,
        ColorImg: ClearDoff,
        ColorCode: "810",
        ColorName: "CLEAR DOFF",
        UrlVideo: "https://www.youtube.com/embed/kVMJyVxDTJ8",
      },
      {
        idColor: 2,
        ColorImg: FreshGreen,
        ColorCode: "8455",
        ColorName: "FRESH GREEN",
        UrlVideo: "https://www.youtube.com/embed/vp88mqw7EZY",
      },
      {
        idColor: 2,
        ColorImg: ColtYellow,
        ColorCode: "8548",
        ColorName: "COLT YELLOW",
        UrlVideo: "https://www.youtube.com/embed/Q3ThLa7elMM",
      },
      {
        idColor: 2,
        ColorImg: MikroletBlue,
        ColorCode: "822",
        ColorName: "MIKROLET BLUE",
        UrlVideo: "https://www.youtube.com/embed/-W-R_1zUekw",
      },
      {
        idColor: 2,
        ColorImg: Black,
        ColorCode: "839",
        ColorName: "BLACK",
        UrlVideo: "https://www.youtube.com/embed/AZpAeai0jyA",
      },
      {
        idColor: 2,
        ColorImg: White,
        ColorCode: "840",
        ColorName: "WHITE",
        UrlVideo:
          "https://www.youtube.com/embed/rWiEBPV9AYg?si=urjTOm_VK0zrwGqK",
      },
      {
        idColor: 2,
        ColorImg: CoklatMuda,
        ColorCode: "843",
        ColorName: "COKLAT MUDA",
        UrlVideo: "https://www.youtube.com/embed/AfGKHw337qM",
      },
      {
        idColor: 2,
        ColorImg: Clear,
        ColorCode: "8540",
        ColorName: "CLEAR",
        UrlVideo: "https://www.youtube.com/embed/Of3VRXJsFcg",
      },
      {
        idColor: 2,
        ColorImg: SatinGreen,
        ColorCode: "8505",
        ColorName: "SATIN GREEN",
        UrlVideo: "https://www.youtube.com/embed/YMJhFnBrHkY",
      },
      {
        idColor: 2,
        ColorImg: Yellow,
        ColorCode: "841",
        ColorName: "YELLOW",
        UrlVideo: "https://www.youtube.com/embed/F3ypODruBkY",
      },
      {
        idColor: 2,
        ColorImg: IndigoBlue,
        ColorCode: "8502",
        ColorName: "INDIGO BLUE",
        UrlVideo: "https://www.youtube.com/embed/h19smUX6E9Y",
      },
      {
        idColor: 2,
        ColorImg: SoftPink,
        ColorCode: "827",
        ColorName: "SOFT PINK",
        UrlVideo: "https://www.youtube.com/embed/3NFuleIdhPg",
      },
      {
        idColor: 2,
        ColorImg: ExecutiveGrey,
        ColorCode: "8459",
        ColorName: "EXECUTIVE GREY",
        UrlVideo: "https://www.youtube.com/embed/xHxwNjCrol8",
      },
      {
        idColor: 2,
        ColorImg: DakarYellow,
        ColorCode: "8546",
        ColorName: "PS. DAKAR YELLOW",
        UrlVideo: "https://www.youtube.com/embed/ZZsfG9us9vc",
      },
      {
        idColor: 2,
        ColorImg: DarkYellow,
        ColorCode: "831",
        ColorName: "DARK YELLOW",
        UrlVideo: "https://www.youtube.com/embed/Cy2iId7rA1U",
      },
      {
        idColor: 2,
        ColorImg: HinoGreen,
        ColorCode: "829",
        ColorName: "HINO GREEN",
        UrlVideo: "https://www.youtube.com/embed/6iRfiQJ580A",
      },
      {
        idColor: 2,
        ColorImg: ChromeYellow,
        ColorCode: "825",
        ColorName: "CHROME YELLOW",
        UrlVideo: "https://www.youtube.com/embed/aG410tn2SSw",
      },
      {
        idColor: 2,
        ColorImg: CeruleanBlue,
        ColorCode: "819",
        ColorName: "CERULEAN BLUE",
        UrlVideo: "https://www.youtube.com/embed/8mJhhG0u5dI",
      },
      {
        idColor: 2,
        ColorImg: RosePink,
        ColorCode: "830",
        ColorName: "ROSE PINK",
        UrlVideo: "https://www.youtube.com/embed/wVFVnAATVMY",
      },
      {
        idColor: 2,
        ColorImg: MediumGrey,
        ColorCode: "826",
        ColorName: "MEDIUM GREY",
        UrlVideo: "https://www.youtube.com/embed/_XZkaJ4sJRA",
      },
      {
        idColor: 2,
        ColorImg: SunkistOrange,
        ColorCode: "833",
        ColorName: "SUNKIST ORANGE",
        UrlVideo: "https://www.youtube.com/embed/vsaqIBSzwPQ",
      },
      {
        idColor: 2,
        ColorImg: Orange,
        ColorCode: "814",
        ColorName: "ORANGE",
        UrlVideo: "https://www.youtube.com/embed/NHbsXc7G92w",
      },
      {
        idColor: 2,
        ColorImg: DeepGreen,
        ColorCode: "813",
        ColorName: "DEEP GREEN",
        UrlVideo: "https://www.youtube.com/embed/jUu3moh7FPg",
      },
      {
        idColor: 2,
        ColorImg: OlimpiaYellow,
        ColorCode: "8537",
        ColorName: "OLIMPIA YELLOW",
        UrlVideo: "https://www.youtube.com/embed/p5wMD_g3lXI",
      },
      {
        idColor: 2,
        ColorImg: ExecutiveBlue,
        ColorCode: "8454",
        ColorName: "EXCLUSIVE BLUE",
        UrlVideo: "https://www.youtube.com/embed/z5xGLQDn6eA",
      },
      {
        idColor: 2,
        ColorImg: VictoriaBlue,
        ColorCode: "817",
        ColorName: "VICTORIA BLUE",
        UrlVideo: "https://www.youtube.com/embed/pu_iWzFThL0",
      },
      {
        idColor: 2,
        ColorImg: LightGrey,
        ColorCode: "834",
        ColorName: "LIGHT GREY",
        UrlVideo: "https://www.youtube.com/embed/YvgDfYTA6SM",
      },
      {
        idColor: 2,
        ColorImg: SpecialRed,
        ColorCode: "886",
        ColorName: "SPECIAL RED",
        UrlVideo: "https://www.youtube.com/embed/EICgwPu1CEM",
      },
      {
        idColor: 2,
        ColorImg: Maroon,
        ColorCode: "838",
        ColorName: "MAROON",
        UrlVideo: "https://www.youtube.com/embed/bfu7_npDbsY",
      },
      {
        idColor: 2,
        ColorImg: PhoenixGreen,
        ColorCode: "8566",
        ColorName: "PHOENIX GREEN",
        UrlVideo: "https://www.youtube.com/embed/IwasBRDawBY",
      },
      {
        idColor: 2,
        ColorImg: NavyBlue,
        ColorCode: "832",
        ColorName: "NAVY BLUE",
        UrlVideo: "https://www.youtube.com/embed/YXl4vcdJ--E",
      },
      {
        idColor: 2,
        ColorImg: StrongBlue,
        ColorCode: "8431",
        ColorName: "STRONG BLUE",
        UrlVideo: "https://www.youtube.com/embed/uVpjun6GxJ4",
      },
      {
        idColor: 2,
        ColorImg: PurpleAngel,
        ColorCode: "818",
        ColorName: "PURPLE ANGEL",
        UrlVideo: "https://www.youtube.com/embed/QHCiDRSEXWU",
      },
      {
        idColor: 2,
        ColorImg: DarkGrey,
        ColorCode: "824",
        ColorName: "DARK GREY",
        UrlVideo: "https://www.youtube.com/embed/DtcZasPQUt4",
      },
      {
        idColor: 2,
        ColorImg: SignalRed,
        ColorCode: "823",
        ColorName: "SIGNAL RED",
        UrlVideo: "https://www.youtube.com/embed/R1lK2Knmgfc",
      },
      {
        idColor: 2,
        ColorImg: MadorasBrown,
        ColorCode: "8773",
        ColorName: "MADORAS BROWN",
        UrlVideo: "https://www.youtube.com/embed/fZ749aEH64Q",
      },
      {
        idColor: 2,
        ColorImg: ToscaGreen,
        ColorCode: "8460",
        ColorName: "TOSCA GREEN",
        UrlVideo: "https://www.youtube.com/embed/2sOrp7yfgKc",
      },
      {
        idColor: 2,
        ColorImg: SapphireBlue,
        ColorCode: "8432",
        ColorName: "SAPPHIRE BLUE",
        UrlVideo: "https://www.youtube.com/embed/B1mUk7KrmCY",
      },
      {
        idColor: 2,
        ColorImg: OceanBlue,
        ColorCode: "821",
        ColorName: "OCEAN BLUE",
        UrlVideo:
          "https://www.youtube.com/embed/N2q_Mxtmv2A?si=4dUoT9I1ccudw7Zw",
      },
      {
        idColor: 2,
        ColorImg: BellFlower,
        ColorCode: "8457",
        ColorName: "BELL FLOWER",
        UrlVideo: "https://www.youtube.com/embed/XlYcCGGr4To",
      },
      {
        idColor: 2,
        ColorImg: ArmyGreen,
        ColorCode: "8522",
        ColorName: "ARMY GREEN",
        UrlVideo: "https://www.youtube.com/embed/6-KgEJG2slo",
      },
      {
        idColor: 2,
        ColorImg: ExclusiveMaroon,
        ColorCode: "8210",
        ColorName: "EXCLUSIVE MAROON",
        UrlVideo: "https://www.youtube.com/embed/SXCAg3REeCo",
      },
      {
        idColor: 2,
        ColorImg: TobaccoBrown,
        ColorCode: "8461",
        ColorName: "TOBACCO BROWN",
        UrlVideo: "https://www.youtube.com/embed/JZMvsYaUsxM",
      },
      {
        idColor: 2,
        ColorImg: RoyalBlue,
        ColorCode: "8435",
        ColorName: "ROYAL BLUE",
        UrlVideo: "https://www.youtube.com/embed/c4zhF5gpE0A",
      },
      {
        idColor: 2,
        ColorImg: DarkBlue,
        ColorCode: "828",
        ColorName: "DARK BLUE",
        UrlVideo: "https://www.youtube.com/embed/vtnPCH6KrxE",
      },
      {
        idColor: 2,
        ColorImg: BlackSemiDoff,
        ColorCode: "883",
        ColorName: "BLACK SEMI DOFF",
        UrlVideo: "https://www.youtube.com/embed/KKpTgRXQvDg",
      },
      {
        idColor: 2,
        ColorImg: Magenta,
        ColorCode: "811",
        ColorName: "MAGENTA",
        UrlVideo: "https://www.youtube.com/embed/U7z-fBDkTRU?si",
      },
      {
        idColor: 2,
        ColorImg: GojekGreen,
        ColorCode: "812",
        ColorName: "GOJEK GREEN",
        UrlVideo: "https://www.youtube.com/embed/eZmsqXPUtjc",
      },
    ],
  },
  {
    id: 2,
    ColorsName: "METALLIC COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: SpecialPurple,
        ColorCode: "8145",
        ColorName: "SPECIAL PURPLE",
        UrlVideo: "https://www.youtube.com/embed/bjgK3zWFLAs",
      },
      {
        idColor: 1,
        ColorImg: DaytonaViolet,
        ColorCode: "8267",
        ColorName: "DAYTONA VIOLET",
        UrlVideo: "https://www.youtube.com/embed/BWprQ0kjJBE",
      },
      {
        idColor: 1,
        ColorImg: FormicaBeige,
        ColorCode: "8253",
        ColorName: "FORMICA BEIGE",
        UrlVideo: "https://www.youtube.com/embed/C6AmpVv5oOE",
      },
      {
        idColor: 1,
        ColorImg: ClearMetallic,
        ColorCode: "858",
        ColorName: "CLEAR METALLIC",
        UrlVideo: "https://www.youtube.com/embed/M6G2_ln-eSM",
      },
      {
        idColor: 1,
        ColorImg: SilverMetallic,
        ColorCode: "8580",
        ColorName: "SILVER METALLIC",
        UrlVideo: "https://www.youtube.com/embed/Y4WS8BMfZlA",
      },
      {
        idColor: 1,
        ColorImg: MetallicGrey,
        ColorCode: "8198",
        ColorName: "METALLIC GREY",
        UrlVideo: "https://www.youtube.com/embed/R4uHLmkn1ks",
      },
      {
        idColor: 1,
        ColorImg: MetallicBlack,
        ColorCode: "8139",
        ColorName: "METALLIC BLACK",
        UrlVideo: "https://www.youtube.com/embed/-e8mhVtcWvE",
      },
      {
        idColor: 1,
        ColorImg: BrungRed,
        ColorCode: "8271",
        ColorName: "BURGUND RED",
        UrlVideo: "https://www.youtube.com/embed/ZJw-8XPOAqs",
      },
      {
        idColor: 1,
        ColorImg: RedPurple,
        ColorCode: "8142",
        ColorName: "RED PURPLE",
        UrlVideo: "https://www.youtube.com/embed/1TfNDeWHCEY",
      },
      {
        idColor: 1,
        ColorImg: AnthraciteGrey,
        ColorCode: "8265",
        ColorName: "ANTHRACITE GREY",
        UrlVideo: "https://www.youtube.com/embed/ZcZ5pLC2LkY",
      },
      {
        idColor: 1,
        ColorImg: NeonGreen,
        ColorCode: "8193",
        ColorName: "NEON GREEN",
        UrlVideo: "https://www.youtube.com/embed/Au_sbbnfQpk",
      },
      {
        idColor: 1,
        ColorImg: Neptune,
        ColorCode: "8183",
        ColorName: "NEPTUNE",
        UrlVideo: "https://www.youtube.com/embed/Ex2BffhloVQ",
      },
      {
        idColor: 1,
        ColorImg: CarbicBlue,
        ColorCode: "8160",
        ColorName: "CARIBIC BLUE",
        UrlVideo: "https://www.youtube.com/embed/sv3ArtcW7Pw",
      },
      {
        idColor: 1,
        ColorImg: BluePearl,
        ColorCode: "8191",
        ColorName: "BLUE PEARL",
        UrlVideo: "https://www.youtube.com/embed/x8Tzio9Zb18",
      },
      {
        idColor: 1,
        ColorImg: MetallicRed,
        ColorCode: "8190",
        ColorName: "METALLIC RED",
        UrlVideo: "https://www.youtube.com/embed/BhRA2xJ6qSA",
      },
      {
        idColor: 1,
        ColorImg: NatierBlue,
        ColorCode: "8252",
        ColorName: "NATIER BLUE",
        UrlVideo: "https://www.youtube.com/embed/4S6smcFsiNk",
      },
      {
        idColor: 1,
        ColorImg: PineGreen,
        ColorCode: "8197",
        ColorName: "PINE GREEN",
        UrlVideo: "https://www.youtube.com/embed/TNjq61lsbiI",
      },
      {
        idColor: 1,
        ColorImg: LagunaGreen,
        ColorCode: "8189",
        ColorName: "LAGUNA GREEN",
        UrlVideo: "https://www.youtube.com/embed/F-Bkt4Edupw",
      },
      {
        idColor: 1,
        ColorImg: MetallicYellow,
        ColorCode: "8102",
        ColorName: "METALLIC YELLOW",
        UrlVideo: "https://www.youtube.com/embed/68btbdVPjVk",
      },
      {
        idColor: 1,
        ColorImg: CelestialBlue,
        ColorCode: "8195",
        ColorName: "CELESTIAL BLUE",
        UrlVideo: "https://www.youtube.com/embed/-zvUb8DSpi8",
      },
      {
        idColor: 1,
        ColorImg: SonicBlue,
        ColorCode: "8192",
        ColorName: "SONIC BLUE",
        UrlVideo: "https://www.youtube.com/embed/e0z-94vzocg",
      },
      {
        idColor: 1,
        ColorImg: EmperorRed,
        ColorCode: "8200",
        ColorName: "EMPROR RED",
        UrlVideo: "https://www.youtube.com/embed/LGDSo3-jigo",
      },
      {
        idColor: 1,
        ColorImg: BluePurple,
        ColorCode: "8143",
        ColorName: "BLUE PURPLE",
        UrlVideo: "https://www.youtube.com/embed/jOsiHa1VWI0",
      },
      {
        idColor: 1,
        ColorImg: LightGreen,
        ColorCode: "8380",
        ColorName: "LIGHT GREEN",
        UrlVideo: "https://www.youtube.com/embed/ObCz9RVYZq4",
      },
      {
        idColor: 1,
        ColorImg: CypressGreen,
        ColorCode: "8381",
        ColorName: "CYPRESS GREEN",
        UrlVideo: "https://www.youtube.com/embed/4_xO6g-5lWE",
      },
      {
        idColor: 1,
        ColorImg: MilanoBlue,
        ColorCode: "8161",
        ColorName: "MILANO BLUE",
        UrlVideo: "https://www.youtube.com/embed/cuqEYII85Qw",
      },
      {
        idColor: 1,
        ColorImg: NeonBlue,
        ColorCode: "8511",
        ColorName: "NEON BLUE",
        UrlVideo: "https://www.youtube.com/embed/sMHfyamHYbo",
      },
      {
        idColor: 1,
        ColorImg: SapphireBlack,
        ColorCode: "8512",
        ColorName: "SAPPHIRE BLACK",
        UrlVideo: "https://www.youtube.com/embed/-ORxIclEbkY",
      },
      {
        idColor: 1,
        ColorImg: MetallicBrown,
        ColorCode: "8199",
        ColorName: "METALLIC BROWN",
        UrlVideo: "https://www.youtube.com/embed/CGVifqEVYrc",
      },
      {
        idColor: 1,
        ColorImg: BluishGrey,
        ColorCode: "8251",
        ColorName: "BLUISH GREY",
        UrlVideo: "https://www.youtube.com/embed/B79eqyZIz1k",
      },
      {
        idColor: 1,
        ColorImg: CoralGreen,
        ColorCode: "8187",
        ColorName: "CORAL GREEN",
        UrlVideo: "https://www.youtube.com/embed/y9FLJr2sCpo",
      },
      {
        idColor: 1,
        ColorImg: EccoGreen,
        ColorCode: "8129",
        ColorName: "ECCO GREEN",
        UrlVideo: "https://www.youtube.com/embed/De5bBQdC8ng",
      },
      {
        idColor: 1,
        ColorImg: EmeraldGreen,
        ColorCode: "8196",
        ColorName: "EMERALD GREEN",
        UrlVideo: "https://www.youtube.com/embed/I8aLsVM2M7w",
      },
      {
        idColor: 1,
        ColorImg: CuracaoBlue,
        ColorCode: "8216",
        ColorName: "CURACAO BLUE",
        UrlVideo: "https://www.youtube.com/embed/nWQjYN682Ik",
      },
      {
        idColor: 1,
        ColorImg: CenturyBlue,
        ColorCode: "8194",
        ColorName: "CENTURY BLUE",
        UrlVideo: "https://www.youtube.com/embed/-hVP07QD2sU",
      },
      {
        idColor: 1,
        ColorImg: IronMetallicDoff,
        ColorCode: "8239",
        ColorName: "IRON METALLIC DOFF",
        UrlVideo: "https://www.youtube.com/embed/xWKGPMwv7jw",
      },
      {
        idColor: 1,
        ColorImg: MagentaMetallic,
        ColorCode: "8144",
        ColorName: "MAGENTA METALLIC",
        UrlVideo: "https://www.youtube.com/embed/BiYfnI3VTEs",
      },
    ],
  },
  // {
  //   id: 3,
  //   ColorsName: "METAL COLOURS",
  //   Color: [
  //     {
  //       idColor: 1,
  //       ColorImg: Silver,
  //       ColorCode: "836",
  //       ColorName: "SILVER",
  //       UrlVideo: "https://www.youtube.com/embed/Dun1_57nQYY",
  //     },
  //     {
  //       idColor: 1,
  //       ColorImg: Gold,
  //       ColorCode: "835",
  //       ColorName: "GOLD",
  //       UrlVideo: "https://www.youtube.com/embed/Lv1tcVJKhLQ",
  //     },
  //     {
  //       idColor: 1,
  //       ColorImg: Titanium,
  //       ColorCode: "816",
  //       ColorName: "TITANIUM",
  //       UrlVideo: "https://www.youtube.com/embed/tTRSmM-hlwA",
  //     },
  //     {
  //       idColor: 1,
  //       ColorImg: Bronze,
  //       ColorCode: "850",
  //       ColorName: "BRONZE",
  //       UrlVideo: "https://www.youtube.com/embed/BDn0vpzxdm8",
  //     },
  //   ],
  // },
  {
    id: 3,
    ColorsName: "AUTOMOTIVE COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: ShogunYellow,
        ColorCode: "8177",
        ColorName: "SHOGUN YELLOW",
        UrlVideo: "https://www.youtube.com/embed/VyJcYn3Rl0A",
      },
      {
        idColor: 1,
        ColorImg: ShogunBlue,
        ColorCode: "8175",
        ColorName: "SHOGUN BLUE",
        UrlVideo: "https://www.youtube.com/embed/qsym3DeMcU4",
      },
      {
        idColor: 1,
        ColorImg: JupiterBlue,
        ColorCode: "8172",
        ColorName: "JUPITER BLUE",
        UrlVideo: "https://www.youtube.com/embed/XKOmMy7w8mg",
      },
      {
        idColor: 1,
        ColorImg: JupiterOrange,
        ColorCode: "8174",
        ColorName: "JUPITER ORANGE",
        UrlVideo: "https://www.youtube.com/embed/QHYKYDR9oqA",
      },
      {
        idColor: 1,
        ColorImg: NinjaGreen,
        ColorCode: "8173",
        ColorName: "NINJA GREEN",
        UrlVideo: "https://www.youtube.com/embed/fC2bEJGvwa4",
      },
      {
        idColor: 1,
        ColorImg: ShogunGreen,
        ColorCode: "8176",
        ColorName: "SHOGUN GREEN",
        UrlVideo: "https://www.youtube.com/embed/YLvC8FCr4bA",
      },
      {
        idColor: 1,
        ColorImg: VarioPink,
        ColorCode: "842",
        ColorName: "VARIO PINK",
        UrlVideo: "https://www.youtube.com/embed/5wYzGhlDDrQ",
      },
    ],
  },
  {
    id: 3,
    ColorsName: "MUTIARA COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: MutiaraGrey,
        ColorCode: "893",
        ColorName: "MUTIARA GREY",
        UrlVideo: "https://www.youtube.com/embed/A5I94gFUUvg",
      },
      {
        idColor: 1,
        ColorImg: MutiaraBiru,
        ColorCode: "897",
        ColorName: "MUTIARA BLUE",
        UrlVideo: "https://www.youtube.com/embed/w9tg9ASyYqM",
      },
      {
        idColor: 1,
        ColorImg: MutiaraKuning,
        ColorCode: "892",
        ColorName: "MUTIARA KUNING",
        UrlVideo: "https://www.youtube.com/embed/HLGV1x4fCBI",
      },
      {
        idColor: 1,
        ColorImg: MutiaraPutih,
        ColorCode: "891",
        ColorName: "MUTIARA PUTIH",
        UrlVideo: "https://www.youtube.com/embed/KzfcyzyMWgY",
      },
      {
        idColor: 1,
        ColorImg: MutiaraPink,
        ColorCode: "896",
        ColorName: "MUTIARA PINK",
        UrlVideo: "https://www.youtube.com/embed/5wmPnTfffQA",
      },
      {
        idColor: 1,
        ColorImg: MutiaraUngu,
        ColorCode: "894",
        ColorName: "MUTIARA UNGU",
        UrlVideo: "https://www.youtube.com/embed/nZ7I383qqrM",
      },
      {
        idColor: 1,
        ColorImg: MutiaraHijau,
        ColorCode: "895",
        ColorName: "MUTIARA HIJAU",
        UrlVideo: "https://www.youtube.com/embed/fGXpkkHpOGo",
      },
    ],
  },
  {
    id: 3,
    ColorsName: "HEAT RESISTANCE COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: HI_Silver,
        ColorCode: "",
        ColorName: "SILVER",
        UrlVideo: "https://www.youtube.com/embed/sRe1_R6bg0w",
      },
      {
        idColor: 1,
        ColorImg: Black,
        ColorCode: "",
        ColorName: "BLACK",
        UrlVideo: "https://www.youtube.com/embed/2Noia7nBQlg",
      },
      {
        idColor: 1,
        ColorImg: HI_Bronze,
        ColorCode: "",
        ColorName: "BRONZE",
        UrlVideo: "https://www.youtube.com/embed/yRJ2KUUU8ow",
      },
    ],
  },
  {
    id: 3,
    ColorsName: "FLUORESCENT COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: Red,
        ColorCode: "8001",
        ColorName: "RED",
        UrlVideo: "https://www.youtube.com/embed/brjDzWkYNn0",
      },
      {
        idColor: 1,
        ColorImg: Pink,
        ColorCode: "8002",
        ColorName: "PINK",
        UrlVideo: "https://www.youtube.com/embed/Ln6BcwCRgyw",
      },
      {
        idColor: 1,
        ColorImg: Green,
        ColorCode: "8003",
        ColorName: "GREEN",
        UrlVideo: "https://www.youtube.com/embed/GwkhShWbspY",
      },
      {
        idColor: 1,
        ColorImg: Blue,
        ColorCode: "8004",
        ColorName: "BLUE",
        UrlVideo: "https://www.youtube.com/embed/CQ8dCZGhSNg",
      },
      {
        idColor: 1,
        ColorImg: Yellow,
        ColorCode: "8005",
        ColorName: "YELLOW",
        UrlVideo: "https://www.youtube.com/embed/eeHk2r570ac",
      },
      {
        idColor: 1,
        ColorImg: Orange,
        ColorCode: "8006",
        ColorName: "ORANGE",
        UrlVideo: "https://www.youtube.com/embed/uCwBXbZUukU",
      },
    ],
  },
  {
    id: 3,
    ColorsName: "CANDYTONE COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: HondaRed,
        ColorCode: "801",
        ColorName: "HONDA RED",
        UrlVideo: "https://www.youtube.com/embed/xEH8Wu8aWTc",
      },
      {
        idColor: 1,
        ColorImg: YamahaGreen,
        ColorCode: "804",
        ColorName: "YAMAHA GREEN",
        UrlVideo: "https://www.youtube.com/embed/4AnjPprQ7dM",
      },
      {
        idColor: 1,
        ColorImg: SuzukiBlue,
        ColorCode: "806",
        ColorName: "SUZUKI BLUE",
        UrlVideo: "https://www.youtube.com/embed/gUUO3aJonbA",
      },
      {
        idColor: 1,
        ColorImg: HondaYellow,
        ColorCode: "802",
        ColorName: "HONDA YELLOW",
        UrlVideo: "https://www.youtube.com/embed/J3NuNvCEaDs",
      },
      {
        idColor: 1,
        ColorImg: YamahaRed,
        ColorCode: "808",
        ColorName: "YAMAHA RED",
        UrlVideo: "https://www.youtube.com/embed/woH4xeYS_FE",
      },
      {
        idColor: 1,
        ColorImg: HondaBlue,
        ColorCode: "803",
        ColorName: "HONDA BLUE",
        UrlVideo: "https://www.youtube.com/embed/Th7--v8ty5I",
      },
      {
        idColor: 1,
        ColorImg: CandyPink,
        ColorCode: "805",
        ColorName: "CANDY PINK",
        UrlVideo: "https://www.youtube.com/embed/jgSFT1utSMY",
      },
      {
        idColor: 1,
        ColorImg: CandyViolet,
        ColorCode: "807",
        ColorName: "CANDY VIOLET",
        UrlVideo: "https://www.youtube.com/embed/5leGKQYfLg8",
      },
    ],
  },
  {
    id: 3,
    ColorsName: "SPECIAL COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: Tembaga,
        ColorCode: "888",
        ColorName: "TEMBAGA",
        UrlVideo: "https://www.youtube.com/embed/uaVWGyS4WM8",
      },
      {
        idColor: 1,
        ColorImg: Chrome,
        ColorCode: "\u00A0",
        ColorName: "CHROME",
        UrlVideo: "https://www.youtube.com/embed/h6j1eHHHg60",
      },
      {
        idColor: 1,
        ColorImg: SpecialGold,
        ColorCode: "999",
        ColorName: "SPECIAL GOLD",
        UrlVideo: "https://www.youtube.com/embed/D80rhDhd1Tw",
      },
    ],
  },
  {
    id: 3,
    ColorsName: "UNDERCOAT COLOURS",
    Color: [
      {
        idColor: 1,
        ColorImg: PremierGrey,
        ColorCode: "8020",
        ColorName: "PP PREMIER GREY",
        UrlVideo: "https://www.youtube.com/embed/yXcbL7EGQVY",
      },
      {
        idColor: 1,
        ColorImg: FLINTKOTE,
        ColorCode: "FLINTKOTE",
        ColorName: "ANTI KARAT",
        UrlVideo: "https://www.youtube.com/embed/R-AJ_mqgKTE",
      },
    ],
  },
];

export const DIYContent = [
  {
    DIYImg: DIY1,
    DIYTitle: "Berkreasi Bersama Warna - Warna Dari Diton Spray Paint",
    DIYCaption:
      "Perpaduan warna Black Doff 884 dan Signal Red 823 bisa jadi opsi untuk merubah gaya !",
  },
  {
    DIYImg: DIY2,
    DIYTitle: "Repaint Mobil-Mobilan Ala Amber Robocar Poli",
    DIYCaption:
      "Mobil Ambulance dari seri kartun Robocar Poli yang lucu dan menggemaskan",
  },
  {
    DIYImg: DIY3,
    DIYTitle: "Repaint Helem Dengan Masking Pola dan Warna Khas Gundam RX-78-2",
    DIYCaption:
      "Bagi pecinta Gundam, sudah tidak asing lagi kan dengan Gundam RX-78-2",
  },
  {
    DIYImg: DIY4,
    DIYTitle: "Ubah Warna Remote Control Mu Dengan Diton Spray Paint",
    DIYCaption:
      "Koleksi warna Dari Diton Spray Paint untuk RC kamu dengan biar makin kece",
  },
  {
    DIYImg: DIY5,
    DIYTitle: "Helm Sepeda Motif Helm Goblin di Film Spiderman",
    DIYCaption:
      "Bikin helm sepeda motif helm goblin di film Spiderman ? Bisa banget !",
  },
  {
    DIYImg: DIY6,
    DIYTitle: "Pengaplikasian Warna Hitam Glossy Menggunakan Body Tamiya",
    DIYCaption:
      "Nah kali ini  mau aplikasikan di body Tamiya, bisa jadi referensi nih untuk kalian yang mau repaint",
  },
  {
    DIYImg: DIY7,
    DIYTitle: "Repaint Discast Biar Makin Kece",
    DIYCaption:
      "repaint dengan Diton Spray Paint membuat tampilan Diecast kalian makin kece dan keren",
  },
  {
    DIYImg: DIY8,
    DIYTitle: "Repaint RC DRIFT",
    DIYCaption:
      "Mobil Ambulance dari seri kartun Robocar Poli yang lucu dan menggemaskan",
  },
  {
    DIYImg: DIY9,
    DIYTitle: "Hadiah  Hari Ibu, Gambar Canvas Buah Tangan Utuk Ibu",
    DIYCaption:
      "Kasih ibu sepanjang masa! Menyambut hari ibu, kali ini mimin mau kasih referensi hadiah untuk ibu kalian tercinta dirumah dengan hasil kreasi dari tangan kalian sendiri nih.",
  },
  {
    DIYImg: DIY10,
    DIYTitle: "",
    DIYCaption: "",
  },
  {
    DIYImg: DIY11,
    DIYTitle: "",
    DIYCaption: "",
  },
  {
    DIYImg: DIY12,
    DIYTitle: "",
    DIYCaption: "",
  },
];
