import { useEffect } from "react";
import "./App.css";
import ColorChart from "./component/ColorChart";
import Home from "./component/Home";
import DIY from "./component/DIY";
import Product from "./component/Product";
import Standart from "./component/Standart";
import HITemp from "./component/HItemp";
import Chrome from "./component/Chrome";
import GoldSeries from "./component/GoldSeries";
import Flintkote from "./component/Flintkote";
import Magic from "./component/Magic";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./component/ScrollToTop";
import ReactGA from "react-ga4";
ReactGA.initialize("170780770");

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} exact />

            <Route path="Products" element={<Product />}>
              <Route index element={<Navigate to="Standart" />} />
              <Route path="Standart" element={<Standart />} />
              <Route path="HITemp" element={<HITemp />} />
              <Route path="Chrome" element={<Chrome />} />
              <Route path="Flintkote" element={<Flintkote />} />
              <Route path="Magic" element={<Magic />} />
              <Route path="diton-gold-series" element={<GoldSeries />} />
            </Route>
            <Route path="DIY" element={<DIY />} />
            <Route path="ColorChart" element={<ColorChart />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
};

export default App;
