import React, { Component } from "react";
import ditonLogo from "../assets/images/logo/Logo SP.png";
import { MenuData } from "./MenuData";
import "./NavbarStyles.css";
// import Home from "./Home";
// import Product from "./Product";
// import ColorChart from './ColorChart';
// import DIY from "./DIY";
import { Link } from "react-router-dom";

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  render() {
    return (
      <nav className="NavbarItems">
        <a href="/">
          <img className="Logo" src={ditonLogo} alt="Logo Diton Spray Paint" />
        </a>
        <div className="icon-menu" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuData.map((item, index) => {
            return (
              <li key={index}>
                <Link className={item.cName} to={item.url}>
                  {item.title}
                </Link> 
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
