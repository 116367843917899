import React from "react";
import "./SprayPaint.css";
import "./HomeStyles.css";
import { USPDataSprayPaint } from "./MenuData";
import icon from "../assets/images/logo/iconUSP.png";
import ReactTextTransition, { presets } from "react-text-transition";
import { Ratio } from "react-bootstrap";
import CoverStandart from "../assets/images/cover/Cover-Web-Spray-Paint.jpg";
import HTU1 from "../assets/images/HowToUse/Spray-Paint/DS 1.jpg";
import HTU2 from "../assets/images/HowToUse/Spray-Paint/DS 2.jpg";
import HTU3 from "../assets/images/HowToUse/Spray-Paint/DS 3.jpg";
import katalog from "../assets/images/color_chart/baner-katalog-standart2.jpg"

const Product = () => {
  return (
    <div className="container" id="standart">
      <div className="Product-details">
        <div className="Product-img">
          <img src={CoverStandart} />
        </div>
        <div className="Product-desc">
          <h2>DITON SPRAY PAINT</h2>
          <h4>Cat Semprot Akrilik Serbaguna </h4>
          <p>
          Diton Spray Paint adalah cat semprot multiguna yang hadir lebih dari 100 warna yang menarik dan modern. Diton Spray Paint memiliki sifat multipurpose sehingga dapat diaplikasikan pada berbagai macam permukaan media. Diton Spray Paint multiguna tidak hanya untuk mewarnai tetapi juga untuk memperbaiki warna berbagai media seperti kendaraan bermotor dan benda lain yang terbuat dari besi, kayu, plastik, kaca, kain, permukaan dinding, dan lain-lain.
          </p>
        </div>
        <div className="usp">
          {USPDataSprayPaint.map((item, index) => {
            return (
              <div className="USPCard" key={index}>
                <div className="USPCardImg">
                  <img alt="logo" src={item.img} />
                </div>
                <div className="USPCardTitle">{item.title}</div>
                <div className="USPCardDesc">{item.dec}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        <div className="container">
          <h1>KATALOG DIGITAL</h1>
            <div className="katalog_digital_img">
              <a rel="noopener noreferrer" href="https://ditonspraypaint.com/digital-catalog/" target="_blank">
              <img className="imgkatalog" src={katalog} alt="KATALOG DIGITAL DITON SPRAY PAINT" />
              </a>
            </div>
        </div>

      </div>
      <div className="HowToUse">
        <h2>Cara Pemakaian</h2>
        <div className="HowToUse-img-container">
        <div className="HowToUse-img">
            <img alt="" src={HTU1} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU2} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU3} />
          </div>
        </div>
      </div>
      <div className="HowToApply">
        <p>Berikut adalah empat langkah dasar untuk pemakaian DITON SPRAY PAINT:</p>
        <ul className="">
          <li>
            Bersihkan dan amplas permukaan media yang akan dicat, dipastikan bersih kering bebas dari minyak, karat, kotoran , dll.{" "}
          </li>
          <li>
            Kocoklah kaleng selama 60 detik secara memutar hingga cat tercampur rata.
          </li>
          <li>
            Semprotkan 2-3 lapisan tipis merata dengan jarak semprot -+ 15cm. Beri jeda -+ 10 menit untuk setiap lapsiannya.
          </li>
          {/* <li>Dry: Allow the paint to dry completely between coats, and wait for the final coat to dry thoroughly before using the painted object. The drying time will depend on the type of paint and the environmental conditions.</li> */}
        </ul>
        <p>
        Penting untuk mengikuti petunjuk produsen untuk produk cat semprot DITON tertentu yang Anda gunakan, karena proses pengaplikasiannya mungkin berbeda-beda tergantung pada jenis cat dan permukaan yang Anda pengecatan.
        </p>
        <Ratio aspectRatio="16x9">
        <iframe
          className="VideoHowtoApply"
          src="https://www.youtube.com/embed/aC_0khm43Y4"
        />
        </Ratio>
        
      </div>
    </div>
  );
};
export default Product;
