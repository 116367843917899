import React from "react";
import "./SprayPaint.css";
import "./HomeStyles.css";
import { USPDataGold } from "./MenuData";
import icon from "../assets/images/logo/iconUSP.png";
import ReactTextTransition, { presets } from "react-text-transition";
import { Ratio } from "react-bootstrap";
import CoverGold from "../assets/images/cover/cover-web-gold.jpg";
import HTU1 from "../assets/images/HowToUse/Gold/Gold-1.jpg";
import HTU2 from "../assets/images/HowToUse/Gold/Gold-2.jpg";
import HTU3 from "../assets/images/HowToUse/Gold/Gold-3.jpg";
import HTU4 from "../assets/images/HowToUse/Gold/Gold-4.jpg";
import goldLaunching from "../assets/images/hero/GOLDLAUNCHING.jpg";
const Product = () => {
  return (
    <div className="container ">
      <div className="Product-details">
        <div className="Product-img">
          <img src={CoverGold} />
        </div>
        <div className="Product-desc">
          <h2>DITON GOLD</h2>
          <h4>Provides shiny and luxurious gold effect</h4>
          <p>
          Diton gold 24KT dan 18KT adalah cat semprot yang memberikan hasil efek emas 24 dan 18 karat yang kilau dan mewah, 
          dengan daya tutup sempurna dan waktu pengeringan yang cepat. Memberikan kemewahan instan untuk setiap kebutuhan dekorasi: 
          frame foto, objek seni, dan ornamen; Otomotif: sepeda, motor, mobil, dan helm.
          </p>
        </div>
        <div className="usp">
          {USPDataGold.map((item, index) => {
            return (
              <div className="USPCard" key={index}>
                <div className="USPCardImg">
                  <img alt="logo" src={item.img} />
                </div>
                <div className="USPCardTitle">{item.title}</div>
                <div className="USPCardDesc">{item.dec}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="HowToUse">
        <h2>HOW TO APPLY</h2>
        <div className="HowToUse-img-container">
          <div className="HowToUse-img">
            <img alt="" src={HTU2} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU3} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU4} />
          </div>
        </div>
      </div>
      <div className="HowToApply">
        
        <p>Berikut adalah tiga langkah dasar untuk pemakaian DITON GOLD:</p>
        <ul className="">
          <li>
            Kocok kaleng selama kurang lebih 60 detik
          </li>
          <li>
            Amplas dan bersihkan media sebelum dilakukan penyemprotan
          </li>
          <li>
            Semprot tipis dengan jarak kurang lebih 15 cm, ulangi 2-3 kali hingga merata.
          </li>
          
        </ul>
        <p>
          {/* It's important to follow the manufacturer's instructions for the
          specific DITON spray paint product you're using, as the application
          process may vary depending on the type of paint and the surface you
          are painting. */}
        </p>
        {/* <Ratio aspectRatio="16x9">
        <iframe
          className="VideoHowtoApply"
          src="https://www.youtube.com/embed/dShMmamCpz4"
        />
        </Ratio> */}
      </div>
      {/* <img className="launching" src={goldLaunching} alt="" /> */}
    </div>
  );
};
export default Product;
