import React from "react";
import "./SprayPaint.css";
import "./HomeStyles.css";
import { USPDataMagic } from "./MenuData";
import icon from "../assets/images/logo/iconUSP.png";
import ReactTextTransition, { presets } from "react-text-transition";
import { Ratio } from "react-bootstrap";
import CoverMagic from "../assets/images/cover/Cover-Web-Flinkote.jpg";
// import HTU1 from "../assets/images/HowToUse/Magic/Magic 1.jpg";
// import HTU2 from "../assets/images/HowToUse/Magic/Magic 2.jpg";
// import HTU3 from "../assets/images/HowToUse/Magic/Magic 3.jpg";
const Product = () => {
  return (
    <div className="container ">
      <div className="Product-details">
        <div className="Product-img">
          {/* <img src={CoverMagic} /> */}
        </div>
        <div className="Product-desc">
          <h2>DITON Magic</h2>
          <h4>Cat Semprot Cocok Untuk Interior dan Exterior </h4>
          <p>
          Magic Spray Paint adalah cat semprot dengan hasil akhir yang mengkilap, cepat kering dan tahan lama. Sangat cepat dan sangat mudah saat disemprotkan pada semua jenis permukaan seperti besi, kayu, plastik dan juga permukaan lainnya.Magic Spray Paint Menyediakan koleksi warna yang cerah, sangat cocok untuk digunakan pada kendaraan bermotor dan perabotan rumah tangga supaya terlihat seperti baru lagi.

Magic Spray Paint memiliki Warna solid yang terang dan kontras sangat cocok untuk art dan dekorasi, jadi anda bebas menuangkan ide kreatifitas anda. Magic Spray Paint sangat cocok untuk benda logam, plastic dan kendaraan bermotor.

          </p>
        </div>
        {/* <div className="usp">
          {USPDataMagic.map((item, index) => {
            return (
              <div className="USPCard" key={index}>
                <div className="USPCardImg">
                  <img alt="logo" src={item.img} />
                </div>
                <div className="USPCardTitle">{item.title}</div>
                <div className="USPCardDesc">{item.dec}</div>
              </div>
            );
          })}
        </div> */}
      </div>
      {/* <div className="HowToUse">
        <h2>Cara Pemakaian</h2>
        <div className="HowToUse-img-container">
          <div className="HowToUse-img">
            <img alt="" src={HTU1} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU2} />
          </div>
          <div className="HowToUse-img">
            <img alt="" src={HTU3} />
          </div>
        </div>
      </div> */}
      {/* <div className="HowToApply">
        
        <p>Berikut adalah empat langkah dasar untuk pemakaian DITON Magic:</p>
        <ul className="">
        <li>
            Bersihkan dan amplas permukaan media yang akan dicat, dipastikan bersih kering bebas dari minyak, karat, kotoran , dll.{" "}
          </li>
          <li>
            Kocoklah kaleng selama 60 detik secara memutar hingga cat tercampur rata.
          </li>
          <li>
            Semprotkan 2-3 lapisan tipis merata dengan jarak semprot -+ 15cm. Beri jeda -+ 10 menit untuk setiap lapsiannya.
          </li>
        </ul>
        <p>
        Penting untuk mengikuti petunjuk produsen untuk produk cat semprot DITON tertentu yang Anda gunakan, karena proses pengaplikasiannya mungkin berbeda-beda tergantung pada jenis cat dan permukaan yang Anda pengecatan.
        </p>
        <Ratio aspectRatio="16x9">
        <iframe
          className="VideoHowtoApply"
          src="https://www.youtube.com/embed/dShMmamCpz4"
        />
        </Ratio>
      </div> */}
    </div>
  );
};
export default Product;
