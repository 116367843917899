import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFacebook, FaInstagram } from "react-icons/fa";
const Footer = () => {
  return (
    <>
      <footer>
        <div>©2023 DITON SPRAY PAINT.</div>
        <div>
          {/* <ul>
            <FontAwesomeIcon icon={FaInstagram}>
              <a href="" />
            </FontAwesomeIcon>
          </ul> */}
          <div class="g-ytsubscribe" data-channel="@ditonspraypaint" data-layout="default" data-count="default"></div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
